import React from 'react'
import { Alert, Tab, Tabs } from 'react-bootstrap'
import Layout from '../../../components/Layout'
import Spinner from '../../../components/spinner'
import { useGetCompanyInfoById, useGetuserDetailsQuery } from '../../../utils/api'
import MyOrganisation from './MyOrganisation'
import MyProfile from './MyProfile'

const MyAccount = () => {
  const getUserProfile = useGetuserDetailsQuery()
  const getCompanyInfoById = useGetCompanyInfoById()

  return (
    <Layout>
      <Layout.Title className="my-4">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="px-4 py-0">My Account</h5>
        </div>
      </Layout.Title>

      <Layout.CardGrey className="my-account-card card card-grey">

        <Tabs
          defaultActiveKey="profile"
          id="myaccount-tab"
          className="rh-simpletabs rh-simpletabs-size-md"
        >
          <Tab eventKey="profile" title="My Profile">
            {(getCompanyInfoById.isSuccess && getUserProfile.isSuccess) && (
              <MyProfile
                // list={getCompanyInfoById.data.data.members.filter(
                //   (c) => c._id === getCompanyInfoById.data.currentUser
                // )}
                list={getUserProfile}
                companyData={getCompanyInfoById}
              />
            )}
          </Tab>
          <Tab eventKey="organisation" title="My Organisation">
            {/*

         */}
            {(getCompanyInfoById.isSuccess && getUserProfile.isSuccess) && (
              <MyOrganisation
                list={getCompanyInfoById}
                userInfo={getUserProfile.data}
                updateCompany={getCompanyInfoById}
              />
            )}
          </Tab>
        </Tabs>
        {(getCompanyInfoById.isLoading || getUserProfile.isLoading) && (
            <div className="d-flex justify-content-center p-5">
              <Spinner />
            </div>
        )}
          {getCompanyInfoById.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'' + getCompanyInfoById.error.message}
            </Alert>
          )}
      </Layout.CardGrey>
    </Layout>
  )
}

export default MyAccount
